import type { ModelDefinition } from "@/__main__/data-model.mjs";
import createModel, { isRootModel } from "@/__main__/data-model.mjs";
import { RankSymbol } from "@/game-lol/utils/symbol-rank.mjs";

const ValueObject = {
  mean: Number,
  stdev: Number,
};

const divisionStats = {
  assistsByMinute: [ValueObject],
  ccPerMinute: ValueObject,
  championId: Number,
  controlWardsPlacedByMinute: [ValueObject],
  creepScoreByMinute: [ValueObject],
  deathsByMinute: [ValueObject],
  goldSpentAdvantageByMinute: [ValueObject],
  goldSpentByMinute: [ValueObject],
  kdaByMinute: [ValueObject],
  killParticipationByMinute: [ValueObject],
  killsByMinute: [ValueObject],
  levelAdvantageByMinute: [ValueObject],
  levelByMinute: [ValueObject],
  sightWardsPlacedByMinute: [ValueObject],
  spell1CastsPerMinute: ValueObject,
  spell2CastsPerMinute: ValueObject,
  spell3CastsPerMinute: ValueObject,
  spell4CastsPerMinute: ValueObject,
  tier: RankSymbol,
  timeEnemySpentControlledByMinute: [ValueObject],
  totalDamageDoneToChampionsByMinute: [ValueObject],
  totalDamageTakenByMinute: [ValueObject],
  totalGoldByMinute: [ValueObject],
  visionScoreByMinute: [ValueObject],
  wardsKilledByMinute: [ValueObject],
  xpByMinute: [ValueObject],
  yellowTrinketWardsPlacedByMinute: [ValueObject],
} satisfies ModelDefinition;

const apiModelValidation = createModel({
  data: {
    divisionStats: [divisionStats],
    [isRootModel]: true,
  },
});

function transform(data) {
  const divisionStats = apiModelValidation(data);
  return divisionStats.data;
}

export default transform;
