import createModel, {
  arbitraryKeys,
  isRootModel,
  Optional,
  Silent,
} from "@/__main__/data-model.mjs";

const VictimeDamage = {
  basic: Boolean,
  magicDamage: Number,
  name: String,
  participantId: Number,
  physicalDamage: Number,
  spellName: String,
  spellSlot: Number,
  trueDamage: Number,
  type: String,
};

export const model = {
  frameInterval: Number,
  gameId: Number,
  frames: [
    {
      events: [
        {
          afterId: Optional(Number),
          assistingParticipantIds: Optional([Number]),
          beforeId: Optional(Number),
          buildingType: Optional(String),
          bounty: Optional(Number),
          creatorId: Optional(Number),
          gameId: Optional(Number),
          goldGain: Optional(Number),
          itemId: Optional(Number),
          killStreakLength: Optional(Number),
          killerId: Optional(Number),
          killType: Optional(String),
          laneType: Optional(String),
          killerTeamId: Optional(Number),
          level: Optional(Number),
          levelUpType: Silent(String),
          monsterSubType: Optional(String),
          monsterType: Optional(String),
          multiKillLength: Optional(Number),
          name: Optional(String),
          participantId: Silent(Number),
          position: Optional({
            x: Number,
            y: Number,
          }),
          shutdownBounty: Optional(Number),
          skillSlot: Silent(Number),
          teamId: Optional(Number),
          timestamp: Number,
          actualStartTime: Optional(Number),
          realTimestamp: Optional(Number),
          towerType: Optional(String),
          type: String,
          victimDamageDealt: Optional([VictimeDamage]),
          victimDamageReceived: Optional([VictimeDamage]),
          victimId: Optional(Number),
          wardType: Optional(String),
          winningTeam: Optional(Number),
        },
      ],
      participantFrames: {
        [arbitraryKeys]: {
          currentGold: Number,
          goldPerSecond: Number,
          jungleMinionsKilled: Number,
          level: Number,
          minionsKilled: Number,
          participantId: Number,
          timeEnemySpentControlled: Number,
          totalGold: Number,
          xp: Number,
          championStats: {
            abilityHaste: Number,
            abilityPower: Number,
            armor: Number,
            armorPen: Number,
            armorPenPercent: Number,
            attackDamage: Number,
            attackSpeed: Number,
            bonusArmorPenPercent: Number,
            bonusMagicPenPercent: Number,
            ccReduction: Number,
            cooldownReduction: Number,
            health: Number,
            healthMax: Number,
            healthRegen: Number,
            lifesteal: Number,
            magicPen: Number,
            magicPenPercent: Number,
            magicResist: Number,
            movementSpeed: Number,
            omnivamp: Number,
            physicalVamp: Number,
            power: Number,
            powerMax: Number,
            powerRegen: Number,
            spellVamp: Number,
          },
          position: {
            x: Number,
            y: Number,
          },
          damageStats: {
            magicDamageDone: Number,
            magicDamageDoneToChampions: Number,
            magicDamageTaken: Number,
            physicalDamageDone: Number,
            physicalDamageDoneToChampions: Number,
            physicalDamageTaken: Number,
            totalDamageDone: Number,
            totalDamageDoneToChampions: Number,
            totalDamageTaken: Number,
            trueDamageDone: Number,
            trueDamageDoneToChampions: Number,
            trueDamageTaken: Number,
          },
        },
      },
      timestamp: Number,
    },
  ],
  participants: [
    {
      participantId: Number,
      puuid: String,
    },
  ],
  [isRootModel]: true,
};

const apiModelValidation = createModel({
  data: {
    timeline: {
      info: model,
    },
  },
});

function transform(data) {
  data = apiModelValidation(data);

  return data.data.timeline.info;
}

export default transform;
